import styled from '@emotion/styled';

type Props = {
  isExpanded: boolean;
  alwaysOpen: boolean;
};

const Content = styled('div')<Props>(
  ({ isExpanded = false, alwaysOpen = false }) => ({
    marginLeft: '5rem',
    overflow: 'hidden',
    transition: 'max-height 0.8s',
    width: 'calc(100% - 3rem - 3rem - 6rem)', // 100% - playButtonWidth - iconWidth - padding
    ...(!alwaysOpen
      ? {
          maxHeight: isExpanded ? '100vh' : 0,
        }
      : {}),
  }),
);

export default Content;
